.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 14rem;
    height: 5rem;
    top: 10px;
    position: relative;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 3rem;
    color: white;
    right: 14%;
    position: relative;
    top: 17%;
}  

.header-menu>li:hover{
    color:var(--orange);
    cursor: pointer;
    
}

@media screen and (max-width: 767px) {
    .logo{
        width: 12rem;
        height: 4rem;
    }

    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
        
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 1rem 1.5rem;
        gap: 2rem;
        height: fit-content;
        top: 1rem;
    }
    
}