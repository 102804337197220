.Footer-container{
    position: relative;
}

.Footer-container>hr{
    border: 2px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links{
    display: flex;
    gap: 5rem;
}

.social-links>img{
    width: 3rem;
    height: 3rem;
    cursor: pointer;
}

.logo-f>img{
    width: 14rem;
}

.blur-f-1{
    bottom: 0;
    right: 30%;
    width: 35rem;
    height: 12rem;
    filter: blur(100px);
    background: red;
}

.blur-f-2{
    bottom: 0;
    left: 26%;
    width: 31rem;
    height: 12rem;
    filter: blur(100px);
    background: rgba(255,115,0);
}

@media screen and (max-width:768px){
    .social-links>img{
        width: 2rem;
        height: 2rem;
        cursor: pointer;
    }
    
    .logo-f>img{
        width: 10rem;
    }
}